.draggingList
  overflow-y: auto
  height: 505px
  & .item
    display: flex
    align-items: flex-start
    justify-content: flex-start
    width: 100% !important
    padding: 8px
    &:focus,&:active
      background: #F8F8FA 0 0 no-repeat padding-box
      box-shadow: 0 0 12px #41479B33
      border-radius: 0 0 2px 2px
      .drag-icon:before
        content: url("@assets/img/icon/dragHover.svg")


#currencySortableItem
  display: flex
  align-items: flex-start
  justify-content: flex-start
  width: 100% !important
  padding: 8px
  z-index: 1002
  &:focus,&:active
    background: #F8F8FA 0 0 no-repeat padding-box
    box-shadow: 0 0 12px #41479B33
    border-radius: 0 0 2px 2px
    .drag-icon:before
      content: url("@assets/img/icon/dragHover.svg")
