.ant-upload.ant-upload-drag
    background: #FFFFFF 0 0 no-repeat padding-box !important
    border: 1px dashed #DCDCE4
    border-radius: 2px
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover
    border-color: $project-global-color
.ant-upload.ant-upload-drag p.ant-upload-text,
.ant-upload.ant-upload-drag p.ant-upload-hint
    text-align: center
    font: 300 12px/16px "SF Pro Display Regular"
    letter-spacing: 0
    color: #191738
    & .marked
        text-align: center
        text-decoration: underline
        font:  300 14px/16px "SF Pro Display Regular"
        letter-spacing: 0
        color: $project-global-color