.notData
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  flex: 1

  & .infoBox
    &--title
      text-align: center
      font: 18px/23px "Red Hat Display Bold"
      letter-spacing: 0
      color: #FF8659

    &--description
      text-align: center
      font: 16px/23px "Red Hat Display Regular"
      letter-spacing: 0
      color: #11121F

  & .btnGroup
    margin: 24px
